<template>
  <SearchInput :usersStatic="usersStatic" :usersCustom="propValue" @setUser="setUser" />

  <template v-if="propValue && propValue.length">
    <Draggable
      v-model="propValue"
      item-key="id"
      ghost-class="ghost"
      handle=".handle"
      class="users-drag box grid overflow-h"
    >
      <template #item="{ element, index }">
        <UsersDragItem :item="element" :index="index + 1 + length" @remove="removeUser" />
      </template>
    </Draggable>
  </template>

  <BaseAlert v-else type="yellow">Дополнительные участники не найдены</BaseAlert>
</template>

<script setup>
import Draggable from 'vuedraggable'
import { defineEmits, defineProps, toRefs, computed } from 'vue'
import { BaseAlert } from '@/components'
import SearchInput from './departments/SearchInput.vue'
import UsersDragItem from './UsersDragItem.vue'

// Emits
const emits = defineEmits(['update:modelValue'])

// Props
const props = defineProps({
  length: {
    type: Number,
    default: 0
  },
  usersStatic: {
    type: Array,
    default: () => []
  },
  modelValue: {
    type: Array,
    default: () => []
  }
})

// Data
const { length, usersStatic, modelValue } = toRefs(props)

// Computed
const propValue = computed({
  get: () => modelValue.value,
  set: (val) => emits('update:modelValue', val)
})

// Methods
function setUser(item) {
  if (propValue.value) {
    const index = propValue.value.findIndex((el) => el.id === item.id)
    if (index === -1) propValue.value.push({ ...item })
    else propValue.value.splice(index, 1)
  } else {
    propValue.value = []
  }
}
function removeUser(id) {
  const index = propValue.value.findIndex((el) => el.id === id)
  if (index !== -1) propValue.value.splice(index, 1)
}
</script>
