<template>
  <!-- Финальный бос -->
  <FormAutocompleteSearch
    v-if="currentTab === 'final_boss'"
    class="f-1"
    label="Финальный босс"
    placeholder="Выберите финального босса"
    :options="optionsUsers"
    :isLoad="isLoad"
    :isMultiple="false"
    @search="searchUsers($event)"
    v-model="propValueFinalBoss"
  >
    <template #single="{ item }">
      <div v-if="item && item.id" class="grid gtc-auto-1 ggap-10">
        <div class="cover ava-24">
          <img :src="item.avatar || require('@/assets/img/ava.svg')" />
        </div>
        <div class="flex ai-c ggap-10">
          <div class="cut">{{ item.text }}</div>
          <div class="grid ai-c jc-fs">
            <small class="marker white cut">{{ item.position }}</small>
          </div>
        </div>
      </div>
      <div v-else class="t-grey-dark">Выберите финального босса</div>
    </template>
  </FormAutocompleteSearch>

  <UsersDrag v-else-if="currentTab === 'agreementers'" v-model="propValueUsers" />

  <!-- Остальные люди -->
  <FormAutocompleteSearch
    v-else
    class="f-1"
    label=""
    :placeholder="currentPlaceholder"
    :options="optionsUsers"
    :isLoad="isLoad"
    :isMultiple="true"
    @search="searchUsers($event)"
    v-model="propValueUsers"
  >
    <template #tag="{ item }">
      <ItemUser :item="item" @remove="removeItem" />
    </template>
  </FormAutocompleteSearch>
</template>

<script setup>
import { useStore } from 'vuex'
import { defineEmits, defineProps, toRefs, ref, computed } from 'vue'
import { FormAutocompleteSearch, ItemUser } from '@/components'
import UsersDrag from '../UsersDrag.vue'

// Emits
const emits = defineEmits(['update:users', 'update:finalBoss'])

// Props
const props = defineProps({
  currentTab: {
    type: String,
    default: 'reviewers'
  },
  users: {
    type: [Object, Array],
    default: () => []
  },
  finalBoss: {
    type: Object,
    default: () => ({})
  }
})

// Data
const { currentTab, users, finalBoss } = toRefs(props)
const store = useStore()
const isLoad = ref(false)

// Computed
const propValueUsers = computed({
  get: () => users.value,
  set: (val) => emits('update:users', val)
})
const propValueFinalBoss = computed({
  get: () => finalBoss.value,
  set: (val) => emits('update:finalBoss', val)
})
const optionsUsers = computed(() => store.getters.users || [])
const currentPlaceholder = computed(() => {
  switch (currentTab.value) {
    case 'reviewers':
      return 'Выберите пинающих'

    case 'agreementers':
      return 'Выберите согласующих'

    case 'watchers':
      return 'Выберите подглядывающих'

    default:
      return 'Выберите'
  }
})

// Created
store.dispatch('GET_USERS')

// Methods
async function searchUsers(searchText) {
  isLoad.value = true
  await store.dispatch('GET_USERS', searchText)
  isLoad.value = false
}
function removeItem(id) {
  const index = propValueUsers.value.findIndex((el) => el.id === id)
  propValueUsers.value.splice(index, 1)
}
</script>

<style></style>
