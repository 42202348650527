<template>
  <div class="users-drag-item flex ai-c jc-sb ggap-10" :class="{ link: isRead }" @click="clickAction">
    <div class="users-drag-item__data flex ai-c ggap-10 p-10">
      <div class="users-drag-item__counter t-black marker jc-c flex ai-c">{{ index }}</div>
      <div class="cover ava-30">
        <img :src="item.avatar || require('@/assets/img/ava.svg')" />
      </div>
      <div class="grid">
        <div class="title">{{ item.text || item.fullname || 'Без имени' }}</div>
        <small class="fs-12 t-grey-dark">
          {{ item.specialization || item.position || 'Без должности' }}
        </small>
      </div>
    </div>

    <div v-if="isStatic" class="item-department__body-user-check flex ai-c jc-c pr-15">
      <BaseIcon class="ic-16 grey" v-tippy="'Закреплено'" icon="check-circle" />
    </div>

    <div v-else-if="!isRead" class="users-drag-item__btns flex ai-c h-100">
      <button class="flex ai-c jc-c" @click.stop.prevent="emits('remove', item.id)">
        <BaseIcon class="ic-16 red" icon="delete" />
      </button>
      <button class="flex ai-c jc-c handle">
        <BaseIcon class="ic-16 black" icon="menu" />
      </button>
    </div>
  </div>
</template>

<script setup>
// import { useRouter } from 'vue-router'
import { defineEmits, defineProps, toRefs, inject } from 'vue'
import { BaseIcon } from '@/components'

// Emits
const emits = defineEmits(['remove'])

// Props
const props = defineProps({
  isRead: {
    type: Boolean,
    default: false
  },
  isStatic: {
    type: Boolean,
    default: false
  },
  index: {
    type: Number,
    default: 0
  },
  item: {
    type: Object,
    default: () => ({})
  }
})

// Data
const { isRead, isStatic, index, item } = toRefs(props)
// const router = useRouter()
const profileLink = inject('profileLink')

// Methods
function clickAction() {
  // if (isRead.value) router.push(profileLink + item.value.id)
  if (isRead.value) document.location.href = profileLink + item.value.id
}
</script>

<style lang="scss" scoped>
.users-drag-item {
  border-bottom: var(--b1);

  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    background-color: var(--grey-l);
  }

  &__counter {
    width: 24px;
  }

  &__btns > button {
    border: 0;
    background-color: transparent;
    height: 100%;
    width: 50px;

    &:hover {
      background-color: var(--grey);
    }
    &:active {
      background-color: var(--grey-d);
    }
  }

  &.link {
    cursor: pointer;

    .title {
      color: var(--primary);
    }
  }

  &.ghost {
    background-color: var(--primary-el);
  }

  &.ghost > &__data,
  &.ghost > &__btns {
    opacity: 0 !important;
  }
}
</style>
